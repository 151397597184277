const initialState = {
	priviledges: null
}

export const MemberPriviledgeMenuReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case 'MEMBER_PRIVILEDGES_MENU': {
			// console.log({
			// 	reducer: {
			// 		type: type,
			// 		payload: payload.data
			// 	}
			// })
			return {
				...state,
				priviledges: payload.data.status === 200 ? { ...payload.data.data.data } : null
			}
		}
		case 'SIGNOUT': {
			return {
				...state,
				priviledges: null
			}
		}
		default: return state
	}
}