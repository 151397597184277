import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
// import sessionStorage from 'redux-persist/lib/storage/session'
import { rootReducer } from '../reducer'
// import logger from 'redux-logger'
import storage from "redux-persist/lib/storage"
// import immutableTransform from 'redux-persist-transform-immutable'

const persistConfig = {
  // transforms: [immutableTransform()],
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'arCollected', 'memberPriviledgeMenu', 'visitPlans', 'customerVisitPlan', 'outletCoverage', 'selling', 'merchandise', 'customerSurvey', 'teamAchievement']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const store = createStore(persistedReducer, {}, applyMiddleware(thunk, logger))
const store = createStore(persistedReducer, {}, applyMiddleware(thunk))
const persistor = persistStore(store)

export {
  store,
  persistor
}
