const initialState = {
  user: null,
  // status: null,
  generateToken: null
}

export const AuthReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case 'GENERATE_TOKEN': {
      return {
        ...state,
        generateToken: payload.data.status === 200 ? payload.data.data.data.token : null
      }
    }
    case 'SIGN_IN': {
      return {
        ...state,
        user: { ...payload.status }
      }
    }
    case 'SIGN_IN_V2': {
      return {
        ...state,
        user: {
          ...payload.data.data.data,
          // isLogin: payload.data.status === 203 ? 0 : 1
        }
      }
    }
    case 'SIGNOUT': {
      return {
        ...state,
        user: null
      }
    }

    default: return state
  }
}