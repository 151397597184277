import { combineReducers } from 'redux'
import { AuthReducer } from './auth.reducer'
import { MemberPriviledgeMenuReducer } from './memberPriviledge.reducer'
import { VisitPlanReducer } from './visitPlan.reducer'
import { CustomerVisitPlanReducer } from './customerVisitPlan.reducer'
import { MerchandiseReducer } from './merchandise.reducer'
import { InvoiceListByDih } from './collectionCollected.reducer'
import { CustomerSurveyReducer } from './customerSurvey.reducer'
import { TeamAchievementReducer } from './teamAchievement.reducer'

export const rootReducer = combineReducers({
  auth: AuthReducer,
  memberPriviledgeMenu: MemberPriviledgeMenuReducer,
  visitPlans: VisitPlanReducer,
  customerVisitPlan: CustomerVisitPlanReducer,
  merchandise: MerchandiseReducer,
  arCollected: InvoiceListByDih,
  customerSurvey: CustomerSurveyReducer,
  teamAchievement: TeamAchievementReducer

})
