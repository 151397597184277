const initialState = {
	listSalesman: null,
	month: 1
}

export const TeamAchievementReducer = (state = initialState, { type, payload = {} }) => { 
	switch (type) {
		case 'TEAM_ACHIEVEMENT': {
			// console.log({
			// 	reducer: {
			// 		type: type,
			// 		payload: payload.data.data.items
			// 	}
			// })
			return {
				...state,
				listSalesman: payload.data.success ? { ...payload.data.data } : null
			}
		}
		case 'SET_MONTH_REPORT': {
			return {
				...state,
				month: payload
			}
		}
		default: return state
	}
}