import Axios from 'axios'

export const generateTokenApi = (payload, handleError) =>
  Axios.post(process.env.REACT_APP_BASE_URL + '/api/v1/token', payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res)
    // .catch(error => handleError(error.response))
    .catch(error => error.response)

export const postLoginApi = (jwtToken, payload) =>
  Axios.post(process.env.REACT_APP_BASE_URL + '/api/v1/member/login', payload, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res)
    .catch(error => error.response)

export const postLoginApiV2 = (jwtToken, payload) =>
  Axios.post(process.env.REACT_APP_BASE_URL + '/api/v2/member/login', payload, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res)
    .catch(error => error.response)

