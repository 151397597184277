import { lazy, Suspense, useEffect, /*useState*/ } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { generateTokenAction } from './redux/action/auth.action';

const Login = lazy(() => import('./pages/Login'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const CustomerVisit = lazy(() => import('./pages/CustomerVisit'))
const CustomerVisitDetail = lazy(() => import('./pages/Customer/CustomerVisitsDetail'))
const CustomerVisitOutlet = lazy(() => import('./pages/Customer/CustomerVisitOutlet'))
const CustomerVisitCreate = lazy(() => import('./pages/CustomerVisitCreate'))
const CustomerVisitActivity = lazy(() => import('./pages/CustomerVisitActivity'))
const CustomerVisitReschedule = lazy(() => import('./pages/Customer/CustomerVisitReschedule'))
const CustomerVisitSellingDetailing = lazy(() => import('./pages/Customer/CustomerVisitSellingDetailing'))
const CustomerVisitCollection = lazy(() => import('./pages/Customer/CustomerVisitCollection'))
const CustomerVisitStockRecord = lazy(() => import('./pages/CustomerVisitStockRecord'))
const ReportMenu = lazy(() => import('./pages/ReportMenu'))
const MonthlySalesReport = lazy(() => import('./pages/MonthlySalesReport'))
const VisitReport = lazy(() => import('./pages/VisitReport'))
const AccountProfile = lazy(() => import('./pages/AccountProfile'))
const AccountChangePassword = lazy(() => import('./pages/AccountChangePassword'))
const AccountReminder = lazy(() => import('./pages/AccountReminder'))
const CustomerManagement = lazy(() => import('./pages/CustomerManagement'))
const CustomerManagementUpdate = lazy(() => import('./pages/CustomerManagementUpdate'))
const CustomerVisitVirtualForm = lazy(() => import('./pages/CustomerVisitVirtualForm'))
const MerchandiseProductList = lazy(() => import('./pages/MerchandiseProductList'))
const MerchandiseProductDetail = lazy(() => import('./pages/MerchandiseProductDetail'))
const MerchandiseProductCompetitor = lazy(() => import('./pages/MerchandiseProductCompetitor'))
const PageNotFound = lazy(() => import('./pages/PageNotFound'))
const CustomerVisitReturnProduct = lazy(() => import('./pages/CustomerVisitReturnProduct'))
const CustomerVisitReturnProductDetail = lazy(() => import('./pages/CustomerVisitReturnProductDetail'))
const CustomerVisitCustomerSurvey = lazy(() => import('./pages/CustomerVisitCustomerSurvey'))
const CustomerVisitCustomerSurveyDetail = lazy(() => import('./pages/CustomerVisitCustomerSurveyDetail'))
const CollectionCollected = lazy(() => import('./pages/CollectionCollected'))
const CollectionCollectedHistory = lazy(() => import('./pages/CollectionCollectedHistory'))
const CollectionCollectedInvoiceList = lazy(() => import('./pages/CollectionCollectedInvoiceList'))
const CollectionCollectedAditionalCost = lazy(() => import('./pages/CollectionCollectedAditionalCost'))
const CollectionCollectedAditionalList = lazy(() => import('./pages/CollectionCollectedAditionalList'))
const CollectionReportOutlet = lazy(() => import('./pages/CollectionTrendReport'))
const MonthlyCollectionReport = lazy(() => import('./pages/MonthlyCollectionReport'))
const InvoiceListByOutlet = lazy(() => import('./pages/InvoiceListByOutlet'))
const CustomerVisitCallSummary = lazy(() => import('./pages/CustomerVisitCallSummary'))
const CustomerVisitLocationNotAccurate = lazy(() => import('./pages/CustomerVisitLocationNotAccurate'))
const VisitListMenu = lazy(() => import('./pages/VisitListMenu'))
const VisitPlanTeam = lazy(() => import('./pages/VisitPlanTeam'))
const VisitPlanTeamRejectedDetail = lazy(() => import('./pages/VisitPlanTeamRejectedDetail'))
const VisitPlanTeamApprovedDetail = lazy(() => import('./pages/VisitPlanTeamApprovedDetail'))
const VisitPlanTeamRequestDetail = lazy(() => import('./pages/VisitPlanTeamRequestDetail'))
const UploadCallPlan = lazy(() => import('./pages/UploadCallPlan'))
const Product = lazy(() => import('./pages/Product'))
const ProductList = lazy(() => import('./pages/ProductList'))
const BrandList = lazy(() => import('./pages/BrandList'))
const Customer = lazy(() => import('./pages/Customer'))
const OrderTracking = lazy(() => import('./pages/OrderTracking'))
const OrderTrackingOutlet = lazy(() => import('./pages/OrderTrackingOutlet'))
const VisitComplianceReport = lazy(() => import('./pages/VisitComplianceReport'))
const VisitComplianceReportDetail = lazy(() => import('./pages/VisitComplianceReportDetail'))
const PromoReport = lazy(() => import('./pages/PromoReport'))
const PromoReportDetail = lazy(() => import('./pages/PromoReportDetail'))
const DashboardPerformanceTrend = lazy(() => import('./pages/DashboardPerformanceTrend'))
const TerritoryManagement = lazy(() => import('./pages/TerritoryManagement'))
const TerritoryManagementCreate = lazy(() => import('./pages/TerritoryManagementCreate'))
const TerritoryManagementUpdate = lazy(() => import('./pages/TerritoryManagementUpdate'))
const VisitReportCallActivities = lazy(() => import('./pages/VisitReportCallActivities'))
const SalesReportPrincipal = lazy(() => import('./pages/SalesReport'))
const MerchandiseProductCompetitorCreate = lazy(() => import('./pages/MerchandiseProductCompetitionCreate'))
const MerchandiseProductCreate = lazy(() => import('./pages/MerchandiseProductCreate'))
const ChangePassword = lazy(() => import('./pages/ChangePassword'))
const SalesmanActivity = lazy(() => import('./pages/SalesmanActivity'))
const SalesmanTrackingReport = lazy(() => import('./pages/SalesmanTrackingReport'))
const TargetManagement = lazy(() => import('./pages/TargetManagement'))
const IncentiveReport = lazy(() => import('./pages/IncentiveReport'))
const IncentiveTeamAchievement = lazy(() => import('./pages/IncentiveTeamAchievement'))
const IncentiveTeamAchievementDetail = lazy(() => import('./pages/IncentiveTeamAchievementDetail'))

// const checkPageAccess = (path, permittedPages) => {
//   let isUserAuthorised = false
//   let url = path.replace(/\//g, "")
//   // let allowedActions = []
//   let allowedActionsName = []
//   if (Array.isArray(permittedPages.masterMenus) && permittedPages.masterMenus.length > 0) {
//     isUserAuthorised = permittedPages.masterMenus.find(page => page.memberMenuUrl === url)

//     permittedPages.masterMenus.forEach(element => {
//       // console.log('ele', element)
//       if (element.memberMenuUrl) {
//         allowedActionsName.push(element.memberMenuUrl)
//       }
//     })
//   }
//   return {
//     isUserAuthorised,
//     allowedActionsName
//   }
// }

// const ProtectedRoute = props => {  
//   const { component: Component, path, permittedPages, ...rest } = props
//   // const generateToken = useSelector(state => state.auth.generateToken)
//   const user = useSelector(state => state.auth.user)
//   const masterMenus = useSelector(state => state.memberPriviledgeMenu.priviledges)
//   const checkAccess = checkPageAccess(path, masterMenus)
//   // let firstWords = []
//   //   for (let i=0; i<path.length > 0; i++) {
//   //     let words = masterMenus.masterMenus[i].memberMenuUrl.split(" ")
//   //     firstWords.push(words[0])
//   //   }
//   let a = 'customer-visit/visit-activity/collection'.split(",") 
//   console.log('customer-visit/visit-activity/collection', a)
//   console.log('masterMenus', masterMenus.activityMenus)
//   console.log('allowedActionsName', checkAccess.allowedActionsName)
//   // console.log('firstWords', firstWords.length)
//   // console.log('checkAccess.allowedActionsName', masterMenus.masterMenus.some(i => i.memberMenuUrl.includes('customer-visit')))
//   return <Route
//     {...rest}
//     render={props =>
//       ((checkAccess.isUserAuthorised === false || typeof checkAccess.isUserAuthorised === 'undefined') && user === null )  ? 
//       <Redirect to='/' />  : (checkAccess.allowedActionsName.length === 0) ? <p>page not found</p> : <Component {...props} actions={checkAccess.allowedActionsName} />
//     }
//     />
// };


const ProtectedRoute = ({ component: Component, ...restProps }) => {
  const generateToken = useSelector(state => state.auth.generateToken)
  const user = useSelector(state => state.auth.user)

  return <Route
    exact {...restProps}
    render={
      () => {
        if (user !== null) {
          // return user.isLogin === 1 ? <Component /> : <Redirect to='/change-password' />
          return <Component />
        } else {
          return generateToken && <Redirect to='/' />
        }
      }
    }
  />
}

const ProtectedLogin = ({ component: Component, ...restProps }) => {
  const generateToken = useSelector(state => state.auth.generateToken)
  const user = useSelector(state => state.auth.user)

  return <Route
    exact {...restProps}
    render={
      () => {
        if (user === null || Object.keys(user).length === 0) {
          return generateToken && <Component />
        }

        if (user !== null) {
          return <Redirect to='/dashboard' />
        }
      }
    }
  />
}


const ChangePassRoute = ({ component: Component, ...restProps }) => {
  const generateToken = useSelector(state => state.auth.generateToken)
  const user = useSelector(state => state.auth.user)

  return <Route
    exact {...restProps}
    render={
      () => {
        if (user !== null) {
          return <Component />
        } else {
          return generateToken && <Redirect to='/' />
        }
      }
    }
  />
}

const App = () => {
  const dispatch = useDispatch()
  const masterMenus = useSelector(state => state.memberPriviledgeMenu.priviledges)
  const user = useSelector(state => state.auth.user)
  // const [state, setState] = useState({})

  useEffect(() => {
    // dispatch(
    //   generateTokenAction({
    //     clientKey: process.env.REACT_APP_CLIENT_KEY,
    //     secretKey: process.env.REACT_APP_SECRET_KEY
    //   }, (error) => setState(error)))

    dispatch(
      generateTokenAction({
        clientKey: process.env.REACT_APP_CLIENT_KEY,
        secretKey: process.env.REACT_APP_SECRET_KEY
      })
    )
      .then(res => {
        if (res.status !== 200) {
          console.log(res.config.url + ' - ' + res.statusText)
        }
      })
      .catch(err => console.log('error get token', err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // if (state.status === 400) {
  //   return <p>{state.data.message}</p>
  // }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Suspense
        fallback={
          <div className="bg-loader overlay">
            <div className="flex flex-col cv-spinner">
              <span className="spinner"></span>
            </div>
          </div>
        }>

        <Switch>
          <Route exact path='/health-check' component={() => <p>succes 200</p>} />
          <ProtectedLogin exact path='/' component={Login} />
          <ProtectedRoute exact path='/dashboard' component={Dashboard} />
          <ProtectedRoute path='/dashboard/performance-trend' component={DashboardPerformanceTrend} />
          <ChangePassRoute path='/change-password' component={ChangePassword} />

          {user === null && !!masterMenus ? (
            <>
              <ProtectedRoute exact path='/customer-visit' component={CustomerVisit} />
              <ProtectedRoute path='/customer-visit/detail/:id' component={CustomerVisitDetail} />
              <ProtectedRoute path='/customer-visit/create' component={CustomerVisitCreate} />
              <ProtectedRoute path='/customer-visit/reschedule' component={CustomerVisitReschedule} />
              <ProtectedRoute path='/customer-visit/outlet' component={CustomerVisitOutlet} />
              <ProtectedRoute path='/customer-visit/product-list' component={ProductList} />
              <ProtectedRoute path='/customer-visit/brand-list' component={BrandList} />
              <ProtectedRoute exact path='/customer-visit/visit-activity' component={CustomerVisitActivity} />
              <ProtectedRoute path='/customer-visit/visit-activity/collection' component={CustomerVisitCollection} />
              <ProtectedRoute path='/customer-visit/visit-activity/stock-record' component={CustomerVisitStockRecord} />
              <ProtectedRoute path='/customer-visit/visit-activity/virtual-visit' component={CustomerVisitVirtualForm} />
              <ProtectedRoute path='/customer-visit/visit-activity/selling-detailing' component={CustomerVisitSellingDetailing} />
              <ProtectedRoute exact path='/customer-visit/visit-activity/merchandise' component={MerchandiseProductList} />
              <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-detail/:id' component={MerchandiseProductDetail} />
              <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-competitor/:id' component={MerchandiseProductCompetitor} />
              <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-competitor-create/:id' component={MerchandiseProductCompetitorCreate} />
              <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-detail-create/:id' component={MerchandiseProductCreate} />
              <ProtectedRoute exact path='/customer-visit/visit-activity/return-product' component={CustomerVisitReturnProduct} />
              <ProtectedRoute path='/customer-visit/visit-activity/return-product/detail' component={CustomerVisitReturnProductDetail} />
              <ProtectedRoute path='/customer-visit/visit-activity/customer-survey/detail/:id' component={CustomerVisitCustomerSurveyDetail} />
              <ProtectedRoute path='/customer-visit/visit-activity/customer-survey' component={CustomerVisitCustomerSurvey} />
              <ProtectedRoute exact path='/customer-visit/visit-activity/call-summary' component={CustomerVisitCallSummary} />
              <ProtectedRoute path='/customer-visit/visit-activity/call-summary/location-not-accurate' component={CustomerVisitLocationNotAccurate} />
              <ProtectedRoute exact path='/reports' component={ReportMenu} />
              <ProtectedRoute path='/reports/monthly-sales-report' component={MonthlySalesReport} />
              <ProtectedRoute exact path='/reports/call-report' component={VisitReport} />
              <ProtectedRoute path='/reports/call-report/call-activities' component={VisitReportCallActivities} />
              <ProtectedRoute exact path='/collection-collected' component={CollectionCollected} />
              <ProtectedRoute path='/collection-collected/history-list/:id' component={CollectionCollectedHistory} />
              <ProtectedRoute path='/collection-collected/invoice-list' component={CollectionCollectedInvoiceList} />
              <ProtectedRoute path='/collection-collected/aditional-cost' component={CollectionCollectedAditionalCost} />
              <ProtectedRoute path='/collection-collected/aditional-list/:id' component={CollectionCollectedAditionalList} />
              <ProtectedRoute path='/reports/collection-trend-report' component={CollectionReportOutlet} />
              <ProtectedRoute exact path='/reports/visit-compliance-report' component={VisitComplianceReport} />
              <ProtectedRoute path='/reports/visit-compliance-report/detail' component={VisitComplianceReportDetail} />
              <ProtectedRoute exact path='/reports/promo-report' component={PromoReport} />
              <ProtectedRoute path='/reports/promo-report/detail' component={PromoReportDetail} />
              <ProtectedRoute exact path='/reports/incentive-report' component={IncentiveReport} />
              <ProtectedRoute path='/reports/incentive-report/team-achievement' component={IncentiveTeamAchievement} />
              <ProtectedRoute path='/reports/incentive-report/team-achievement/:id' component={IncentiveTeamAchievementDetail} />
              <ProtectedRoute path='/reports/monthly-collection-report/outlet/invoice-list/:slug' component={InvoiceListByOutlet} />
              <ProtectedRoute exact path='/visit-list' component={VisitListMenu} />
              <ProtectedRoute path='/visit-list/visit-plan-team' component={VisitPlanTeam} />
              <ProtectedRoute path='/visit-list/visit-plan-team/rejected-detail/:id' component={VisitPlanTeamRejectedDetail} />
              <ProtectedRoute path='/visit-list/visit-plan-team/approved-detail/:id' component={VisitPlanTeamApprovedDetail} />
              <ProtectedRoute path='/visit-list/visit-plan-team/request-detail/:id' component={VisitPlanTeamRequestDetail} />
              <ProtectedRoute path='/visit-list/upload-call-plan' component={UploadCallPlan} />
              <ProtectedRoute path='/account/change-password' component={AccountChangePassword} />
              <ChangePassRoute path='/change-password' component={ChangePassword} />
              <ProtectedRoute path='/account/reminder' component={AccountReminder} />
              <ProtectedRoute exact path='/account' component={AccountProfile} />
              <ProtectedRoute exact path='/customer-management' component={Customer} />
              <ProtectedRoute path='/customer-management-update/:id' component={CustomerManagementUpdate} />
              <ProtectedRoute path='/customer-management/customer-management' component={CustomerManagement} />
              <ProtectedRoute path='/customer-management/order-tracking' component={OrderTracking} />
              <ProtectedRoute path='/customer-management/order-tracking-outlet/:id' component={OrderTrackingOutlet} />
              <ProtectedRoute path='/product-list' component={Product} />
              <ProtectedRoute exact path='/reports/sales-trend-report/:category' component={SalesReportPrincipal} />
              <ProtectedRoute exact={true} path='/reports/monthly-collection-report' component={MonthlyCollectionReport} />
              <ProtectedRoute exact path='/salesman-activity' component={SalesmanActivity} />
              <ProtectedRoute exact={true} path='/salesman-activity/territory-management' component={TerritoryManagement} />
              <ProtectedRoute path='/salesman-activity/territory-management/create' component={TerritoryManagementCreate} />
              <ProtectedRoute path='/salesman-activity/territory-management/detail/:id' component={TerritoryManagementUpdate} />
              <ProtectedRoute path='/salesman-activity/salesman-tracking-report' component={SalesmanTrackingReport} />
              <ProtectedRoute exact path='/target-management' component={TargetManagement} />

              <Route path='*' exact={true} component={PageNotFound} />
            </>
          ) : (
            <>

              {masterMenus?.masterMenus?.map(path => {
                if (path.memberMenuUrl === 'customer-visit') {
                  return (
                    <>
                      <ProtectedRoute exact path='/customer-visit' component={CustomerVisit} />
                      <ProtectedRoute path={`/${path.memberMenuUrl}/detail/:id`} component={CustomerVisitDetail} />
                      <ProtectedRoute path={`/${path.memberMenuUrl}/create`} component={CustomerVisitCreate} />
                      <ProtectedRoute path={`/${path.memberMenuUrl}/reschedule`} component={CustomerVisitReschedule} />
                      <ProtectedRoute path={`/${path.memberMenuUrl}/outlet`} component={CustomerVisitOutlet} />
                      <ProtectedRoute path='/customer-visit/product-list' component={ProductList} />
                      <ProtectedRoute path='/customer-visit/brand-list' component={BrandList} />
                      <ProtectedRoute exact path='/customer-visit/visit-activity' component={CustomerVisitActivity} />
                      <ProtectedRoute path='/customer-visit/visit-activity/collection' component={CustomerVisitCollection} />
                      <ProtectedRoute path='/customer-visit/visit-activity/stock-record' component={CustomerVisitStockRecord} />
                      <ProtectedRoute path='/customer-visit/visit-activity/virtual-visit' component={CustomerVisitVirtualForm} />
                      <ProtectedRoute path='/customer-visit/visit-activity/selling-detailing' component={CustomerVisitSellingDetailing} />
                      <ProtectedRoute exact path='/customer-visit/visit-activity/merchandise' component={MerchandiseProductList} />
                      <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-detail/:id' component={MerchandiseProductDetail} />
                      <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-competitor/:id' component={MerchandiseProductCompetitor} />
                      <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-competitor-create/:id' component={MerchandiseProductCompetitorCreate} />
                      <ProtectedRoute path='/customer-visit/visit-activity/merchandise/product-detail-create/:id' component={MerchandiseProductCreate} />
                      <ProtectedRoute exact path='/customer-visit/visit-activity/return-product' component={CustomerVisitReturnProduct} />
                      <ProtectedRoute path='/customer-visit/visit-activity/return-product/detail' component={CustomerVisitReturnProductDetail} />
                      <ProtectedRoute path='/customer-visit/visit-activity/customer-survey/detail/:id' component={CustomerVisitCustomerSurveyDetail} />
                      <ProtectedRoute path='/customer-visit/visit-activity/customer-survey' component={CustomerVisitCustomerSurvey} />
                      <ProtectedRoute exact path='/customer-visit/visit-activity/call-summary' component={CustomerVisitCallSummary} />
                      <ProtectedRoute path='/customer-visit/visit-activity/call-summary/location-not-accurate' component={CustomerVisitLocationNotAccurate} />

                    </>
                  )
                } else if (path.memberMenuUrl === 'product-list') {
                  return <ProtectedRoute path='/product-list' component={Product} />
                } else if (path.memberMenuUrl === 'customer-management') {
                  return (
                    <>
                      <ProtectedRoute exact path='/customer-management' component={Customer} />
                      <ProtectedRoute path='/customer-management-update/:id' component={CustomerManagementUpdate} />
                      <ProtectedRoute path='/customer-management/customer-management' component={CustomerManagement} />
                      <ProtectedRoute path='/customer-management/order-tracking' component={OrderTracking} />
                      <ProtectedRoute path='/customer-management/order-tracking-outlet/:id' component={OrderTrackingOutlet} />
                    </>
                  )
                } else if (path.memberMenuUrl === 'reports') {
                  return (
                    <>
                      <ProtectedRoute exact path='/reports' component={ReportMenu} />
                      <ProtectedRoute path='/reports/monthly-sales-report' component={MonthlySalesReport} />
                      <ProtectedRoute exact path='/reports/call-report' component={VisitReport} />
                      <ProtectedRoute path='/reports/call-report/call-activities' component={VisitReportCallActivities} />
                      <ProtectedRoute exact path='/reports/sales-trend-report/:category' component={SalesReportPrincipal} />
                      <ProtectedRoute exact={true} path='/reports/monthly-collection-report' component={MonthlyCollectionReport} />
                      <ProtectedRoute path='/reports/collection-trend-report' component={CollectionReportOutlet} />
                      <ProtectedRoute exact path='/reports/visit-compliance-report' component={VisitComplianceReport} />
                      <ProtectedRoute path='/reports/visit-compliance-report/detail' component={VisitComplianceReportDetail} />
                      <ProtectedRoute exact path='/reports/promo-report' component={PromoReport} />
                      <ProtectedRoute path='/reports/promo-report/detail' component={PromoReportDetail} />
                      <ProtectedRoute exact path='/reports/incentive-report' component={IncentiveReport} />
                      <ProtectedRoute path='/reports/incentive-report/team-achievement' component={IncentiveTeamAchievement} />
                      <ProtectedRoute path='/reports/incentive-report/team-achievement/:id' component={IncentiveTeamAchievementDetail} />
                      <ProtectedRoute path='/reports/monthly-collection-report/outlet/invoice-list/:slug' component={InvoiceListByOutlet} />
                    </>
                  )
                } else if (path.memberMenuUrl === 'customer-management') {
                  return (
                    <>
                      <ProtectedRoute exact path='/customer-management' component={Customer} />
                      <ProtectedRoute path='/customer-management-update/:id' component={CustomerManagementUpdate} />
                      <ProtectedRoute path='/customer-management/customer-management' component={CustomerManagement} />
                      <ProtectedRoute path='/customer-management/order-tracking' component={OrderTracking} />
                      <ProtectedRoute path='/customer-management/order-tracking-outlet/:id' component={OrderTrackingOutlet} />
                    </>
                  )
                } else if (path.memberMenuUrl === 'visit-list') {
                  return (
                    <>
                      <ProtectedRoute exact path='/visit-list' component={VisitListMenu} />
                      <ProtectedRoute path='/visit-list/visit-plan-team' component={VisitPlanTeam} />
                      <ProtectedRoute path='/visit-list/visit-plan-team/rejected-detail/:id' component={VisitPlanTeamRejectedDetail} />
                      <ProtectedRoute path='/visit-list/visit-plan-team/approved-detail/:id' component={VisitPlanTeamApprovedDetail} />
                      <ProtectedRoute path='/visit-list/visit-plan-team/request-detail/:id' component={VisitPlanTeamRequestDetail} />
                      <ProtectedRoute path='/visit-list/upload-call-plan' component={UploadCallPlan} />
                    </>
                  )
                } else if (path.memberMenuUrl === 'account') {
                  return (
                    <>
                      <ProtectedRoute path='/account/change-password' component={AccountChangePassword} />
                      <ChangePassRoute path='/change-password' component={ChangePassword} />
                      <ProtectedRoute path='/account/reminder' component={AccountReminder} />
                      <ProtectedRoute exact path='/account' component={AccountProfile} />
                    </>
                  )
                } else if (path.memberMenuUrl === 'salesman-activity') {
                  return (
                    <>
                      <ProtectedRoute exact path='/salesman-activity' component={SalesmanActivity} />
                      <ProtectedRoute exact={true} path='/salesman-activity/territory-management' component={TerritoryManagement} />
                      <ProtectedRoute path='/salesman-activity/territory-management/create' component={TerritoryManagementCreate} />
                      <ProtectedRoute path='/salesman-activity/territory-management/detail/:id' component={TerritoryManagementUpdate} />
                      <ProtectedRoute path='/salesman-activity/salesman-tracking-report' component={SalesmanTrackingReport} />
                    </>
                  )
                } else if (path.memberMenuUrl === 'collection-collected') {
                  return (
                    <>
                      <ProtectedRoute exact path='/collection-collected' component={CollectionCollected} />
                      <ProtectedRoute path='/collection-collected/history-list/:id' component={CollectionCollectedHistory} />
                      <ProtectedRoute path='/collection-collected/invoice-list' component={CollectionCollectedInvoiceList} />
                      <ProtectedRoute path='/collection-collected/aditional-cost' component={CollectionCollectedAditionalCost} />
                      <ProtectedRoute path='/collection-collected/aditional-list/:id' component={CollectionCollectedAditionalList} />
                    </>
                  )
                } else if (path.memberMenuUrl === 'target-management') {
                  return (
                    <>
                      <ProtectedRoute exact path='/target-management' component={TargetManagement} />
                    </>
                  )
                } else {
                  return false
                }
              })
              }

            </>
          )}
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
