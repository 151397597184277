const initialState = {
	visitPlan: null,
	visitProgress: null
}

export const CustomerVisitPlanReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case 'CUSTOMER_VISIT_PLAN': {
			// console.log({
			// 	reducer: {
			// 		type: type,
			// 		payload: payload.data
			// 	}
			// })
			return {
				...state,
				visitPlan: payload.data.success ? { ...payload.data } : null
			}
		}
		case 'CUSTOMER_VISIT_PROGRESS': {
			// console.log({
			// 	reducer: {
			// 		type: type,
			// 		payload: payload.data
			// 	}
			// })
			return {
				...state,
				visitProgress: payload.data.success ? { ...payload.data } : null
			}
		}
		default: return state
	}
}