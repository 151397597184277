const initialState = {
	publicHoliday: null,
	visitPlanDetail: null,
	lastSummaryByOutlet: null
}

export const VisitPlanReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case 'PUBLIC_HOLIDAY': {
			// console.log({
			// 	reducer: {
			// 		type: type,
			// 		payload: payload.data
			// 	}
			// })
			return {
				...state,
				publicHoliday: payload.data.success ? { ...payload.data.data } : null
			}
		}
		case 'VISIT_PLAN_BY_ID': {
			// console.log({
			// 	reducer: {
			// 		type: type,
			// 		payload: payload.data
			// 	}
			// })
			return {
				...state,
				visitPlanDetail: { ...payload.data.data }
			}
		}
		default: return state
	}
}