const initialState = {
    dihNo: [],
    listDeposit: null,
    listDepositById: null
  }
  
  export const InvoiceListByDih = (state = initialState, { type, payload = {} })  => {
    switch (type) {
      case 'DIH_NO': {
        return {
          ...state,
          dihNo: payload.data
        }
      }
      case 'GET_LIST_DEPOSIT': {
        return {
          ...state,
          listDeposit: payload
        }
      }
      case 'ADD_NEW_COSTS': {

        return {
          ...state
        }
        // let c = state.listDeposit;
        // console.log('payload', payload)
        // console.log('c', c.depositList)
        // console.log('listDeposit', c.depositList.map(a => {
        //   return a.costs.push()
        // }))

        // let c = {
        //   ...state,
        //   listDeposit: state.listDeposit.depositList.map(a => {
        //     if (a.arDepositId === payload[0].arDepositId) {
        //       return payload
        //     } else {
        //       return { ...state.listDeposit }
        //     }
        //   })
        // }

        // console.log('c', c)

        // console.log('list', state.listDeposit.depositList.map(a => {
        //   return {
        //     ...state,
        //     listDeposit: state.listDeposit.depositList.map(a => {
        //       if (a.arDepositId === payload[0].arDepositId) {
        //         return {
        //           ...state,
        //           listDeposit: payload
        //         }
        //       }
        //     })
        //   }
        // }))
       
      }
      default: return state
    }
  }