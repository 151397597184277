import { generateTokenApi, postLoginApi, postLoginApiV2 } from '../api/auth.api'
import sessionStorage from 'redux-persist/lib/storage/session'

export const generateTokenAction = (payload, handleError) => {
  return async dispatch => {
    try {
      const data = await generateTokenApi(payload, handleError)
      // console.log({
      //   'data token action': data
      // })
      dispatch({
        type: 'GENERATE_TOKEN',
        payload: { data }
      })
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
}

export const postLoginAction = (jwtToken, payload) => {
  return async dispatch => {
    try {
      const data = await postLoginApi(jwtToken, payload)
      dispatch({
        type: 'SIGN_IN',
        payload: { data }
      })      
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
}

export const postLoginActionV2 = (jwtToken, payload) => {
  return async dispatch => {
    try {
      const data = await postLoginApiV2(jwtToken, payload)
      // console.log({
			// 	'data customer login': data
			// })
      dispatch({
        type: 'SIGN_IN_V2',
        payload: { data }
      })
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
}

export const logout = () => {
  return dispatch => {
    dispatch({
      type: 'SIGNOUT'
    })
    sessionStorage.removeItem('persist:root')
  }
}